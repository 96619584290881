
const $ = require('jquery');
window.$ = window.jQuery = $;

import 'bootstrap';
import '@popperjs/core';
import '../../assets/bundles/prcmagicachatsitev2/js/jquery.lazy.min.js';
import '../../assets/bundles/prcmagicachatsitev2/js/slick.min.js' ;
import '../../assets/bundles/prcmagicachatsitev2/js/jquery.mb.YTPlayer.min.js';
import '../../assets/bundles/prcmagicachatsitev2/js/jquery.waypoints.min.js';
import '../../assets/bundles/prcmagicachatsitev2/js/jquery.easing.1.3.js';
import '../../assets/bundles/prcmagicachatsitev2/js/main.js';
import '../scss/magicrecycle.scss';
